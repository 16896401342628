<template>
  <div class="tabs">
    <b-tabs pills content-class="mt-3">
      <b-tab
        v-for="option in tabsOptions"
        :key="option.value"
        :title="option.title"
        :active="value === option.value"
        @click="selectOption(option)"
      >
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabsOptions: []
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('input', option.value)
      this.$emit('change', option.value)
    }
  },
  watch: {
    options: {
      handler() {
        this.tabsOptions = this.options.map(option => ({
          title: option.title || option,
          value: option.value || option
        }))
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
